import { storeToRefs } from 'pinia';
import { createRouter, createWebHistory } from 'vue-router';

import { useCurrentAccountStore } from '@abaninja/data-access-swiss21-account';
import { type Me, useMeStore, type Account, useUserRoleStore } from '@abaninja/data-access-swiss21-users';
import { useAccountRoleStore, useAccountsStore } from '@abaninja/data-access-swiss21-users';
import { useRouterState } from '@abaninja/util-router';

import { routes } from './routes';
import { switchLanguage } from '../plugins/i18n';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

// eslint-disable-next-line complexity
router.beforeEach(async (to) => {
  const { isLoading } = storeToRefs(useRouterState());
  isLoading.value = true;

  // load accounts
  const { requireEntities, entities } = useAccountsStore();

  // load me
  const meStore = useMeStore();
  const { entity } = storeToRefs(meStore);

  try {
    await Promise.all([requireEntities(), meStore.requireEntity()]);
  } catch {
    return false;
  }

  const { setCurrentLevel: setUserRole, needs: needsUserRole } = useUserRoleStore();

  if (entity.value) {
    // set account Role

    setUserRole(entity.value.userRole);

    await switchLanguage(entity.value.language as SupportedLocale);
  }

  if (to.meta.needsAccount && !entities.value.length) {
    // no account present, so redirect to onboarding
    return { name: 'onboarding' };
  }

  const { setCurrentLevel: setAccountRole, needs: needsAccountRole } = useAccountRoleStore();

  if (to.meta.needsAccount) {
    const currentAccountStore = useCurrentAccountStore();
    const { accountRole, entity: currentAccount } = storeToRefs(currentAccountStore);

    try {
      await currentAccountStore.setDefaultAccount();
    } catch {
      return false;
    }

    // set account Role
    setAccountRole(accountRole.value);

    // check if needs redirect to accept agb page
    if (
      !['accept-agb', 'accounts', 'create-account'].includes(to.name?.toString() ?? '')
      && needsAccountRole('OWNER')
      && currentAccount.value
      && (!currentAccount.value.acceptedAgb || !currentAccount.value.acceptedPrivacyPolicy)
    ) {
      return { name: 'accept-agb' };
    }
  }

  if (to.meta.permissions) {
    if (!(to.meta.permissions as Account['accountRole'][])?.some(needsAccountRole)) {
      // no access
      return { name: 'dashboard' };
    }
  }

  if (to.meta.userRoles) {
    if (!(to.meta.userRoles as Me['userRole'][])?.some(needsUserRole)) {
      // no access
      return { name: 'dashboard' };
    }
  }

  return true;
});

router.afterEach(() => {
  const { isLoading, hasLoaded } = storeToRefs(useRouterState());
  isLoading.value = false;
  hasLoaded.value = true;
});

export default router;
