import { computed } from 'vue';
import type { RouteRecordRaw } from 'vue-router';

import { useAccountsStore } from '@abaninja/data-access-swiss21-users';
import { useGlobalI18n } from '@abaninja/util-i18n';

const { global: { t } } = useGlobalI18n();

export const routes: RouteRecordRaw[] = [
  {
    path: 'onboarding',
    name: 'onboarding',
    component: () => import('../OnboardingIndex.vue'),
    meta: {
      title: computed(() => t('messages.onboarding')),
    },
    beforeEnter: () => {
      const { entities } = useAccountsStore();

      if (entities.value.length) {
        return { name: 'dashboard' };
      }

      return true;
    },
  },
  {
    path: 'accept-agb',
    name: 'accept-agb',
    component: () => import('../AcceptAgb.vue'),
    meta: {
      title: computed(() => t('messages.acceptAgb')),
      needsAccount: true,
    },
  },
];
